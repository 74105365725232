import React from "react";
import PropTypes from "prop-types";
import Hand from "./Hand";

class Opponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const classes = ["_opponent", this.props.className];

        const hand = [];
        if (this.props.player.hand) {
            for (let i = 0; i < this.props.player.hand; i++) {
                hand.push(null);
            }
        }

        return (
            <div
                className={classes.join(" ")}
                style={this.props.style}
            >
                <div className={"name"}>
                    <span>{this.props.player.name}</span>

                    {
                        this.props.player.attacking &&
                        (
                            <div className={"__player_attacking"}>greift an</div>
                        )
                    }

                    {
                        this.props.player.defending &&
                        (
                            <div className={"__player_defending"}>verteidigt</div>
                        )
                    }
                </div>

                <Hand
                    hand={hand}
                    style={this.props.handStyle}
                />
            </div>
        );
    }
}

Opponent.propTypes = {
    className: PropTypes.string,
    handStyle: PropTypes.object,
    player: PropTypes.object,
    style: PropTypes.object,
};

export default Opponent;
