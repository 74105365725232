import constants from "../constants";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

console.log("constants", "URL_API", constants.URL_API);

export const create = (playerName, gamePassword) => {
    return fetch(`${constants.URL_API}/create`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ playerName, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const join = (gameId, playerName, gamePassword) => {
    return fetch(`${constants.URL_API}/join`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerName, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const start = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/start`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const status = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/status`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const listGames = () => {
    return fetch(`${constants.URL_API}/list`, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const kick = (gameId, gamePassword, playerId, targetPlayerIndex) => {
    return fetch(`${constants.URL_API}/actions/kick`, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({ gameId, playerId, gamePassword, targetPlayerIndex }),
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const attack = (gameId, gamePassword, playerId, handKey) => {
    return fetch(`${constants.URL_API}/actions/attack`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword, attackingCardKey: handKey }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const shove = (gameId, gamePassword, playerId, handKey) => {
    return fetch(`${constants.URL_API}/actions/shove`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword, handCardKey: handKey }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const defend = (gameId, gamePassword, playerId, handKey, attackKey) => {
    return fetch(`${constants.URL_API}/actions/defend`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword, defendingCardKey: handKey, attackKey }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const doneAttacking = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/doneAttacking`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};

export const takeCardsFromAttacks = (gameId, gamePassword, playerId) => {
    return fetch(`${constants.URL_API}/actions/takeCardsFromAttacks`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ gameId, playerId, gamePassword }),
        credentials: 'include',
        mode: 'cors',
    })
        .then(res => res.json())
        .then(json => {
            return json;
        });
};
