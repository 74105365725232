export default {
    URL_API: process.env.REACT_APP_URL_API,

    ACTION_DO_CREATE: "ACTION_DO_CREATE",
    ACTION_ON_CREATE_SUCCESS: "ACTION_ON_CREATE_SUCCESS",
    ACTION_ON_CREATE_ERROR: "ACTION_ON_CREATE_ERROR",
    ACTION_DO_JOIN: "ACTION_DO_JOIN",
    ACTION_ON_JOIN_SUCCESS: "ACTION_ON_JOIN_SUCCESS",
    ACTION_ON_JOIN_ERROR: "ACTION_ON_JOIN_ERROR",
    ACTION_DO_START: "ACTION_DO_START",
    ACTION_ON_START_SUCCESS: "ACTION_ON_START_SUCCESS",
    ACTION_ON_START_ERROR: "ACTION_ON_START_ERROR",
    ACTION_DO_STATUS: "ACTION_DO_STATUS",
    ACTION_ON_STATUS_SUCCESS: "ACTION_ON_STATUS_SUCCESS",
    ACTION_ON_STATUS_ERROR: "ACTION_ON_STATUS_ERROR",
    ACTION_DO_LIST_GAMES: "ACTION_DO_LIST_GAMES",
    ACTION_ON_LIST_GAMES_SUCCESS: "ACTION_ON_LIST_GAMES_SUCCESS",
    ACTION_ON_LIST_GAMES_ERROR: "ACTION_ON_LIST_GAMES_ERROR",
    ACTION_DO_KICK: "ACTION_DO_KICK",
    ACTION_ON_KICK_SUCCESS: "ACTION_ON_KICK_SUCCESS",
    ACTION_ON_KICK_ERROR: "ACTION_ON_KICK_ERROR",


    ACTION_DO_ATTACK: "ACTION_DO_ATTACK",
    ACTION_ON_ATTACK_SUCCESS: "ACTION_ON_ATTACK_SUCCESS",
    ACTION_ON_ATTACK_ERROR: "ACTION_ON_ATTACK_ERROR",
    ACTION_DO_SHOVE: "ACTION_DO_SHOVE",
    ACTION_ON_SHOVE_SUCCESS: "ACTION_ON_SHOVE_SUCCESS",
    ACTION_ON_SHOVE_ERROR: "ACTION_ON_SHOVE_ERROR",
    ACTION_DO_DEFEND: "ACTION_DO_DEFEND",
    ACTION_ON_DEFEND_SUCCESS: "ACTION_ON_DEFEND_SUCCESS",
    ACTION_ON_DEFEND_ERROR: "ACTION_ON_DEFEND_ERROR",
    ACTION_DO_DONE_ATTACKING: "ACTION_DO_DONE_ATTACKING",
    ACTION_ON_DONE_ATTACKING_SUCCESS: "ACTION_ON_DONE_ATTACKING_SUCCESS",
    ACTION_ON_DONE_ATTACKING_ERROR: "ACTION_ON_DONE_ATTACKING_ERROR",
    ACTION_DO_TAKE_ALL: "ACTION_DO_TAKE_ALL",
    ACTION_ON_TAKE_ALL_SUCCESS: "ACTION_ON_TAKE_ALL_SUCCESS",
    ACTION_ON_TAKE_ALL_ERROR: "ACTION_ON_TAKE_ALL_ERROR",

    ACTION_DO_CLEAR: "ACTION_DO_CLEAR",
};
