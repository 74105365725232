import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

const deepEqual = require("deep-equal");

class Attacks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            attackAngles: [],
            translatesX: [],
            translatesY: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let changed = false;
        let attackAngles = this.state.attackAngles;
        let translatesX = this.state.translatesX;
        let translatesY = this.state.translatesY;

        if (!deepEqual(this.props.attacks, prevProps.attacks)) {
            changed = true;

            const maxAngle = 10;
            const maxTranslation = 2;

            attackAngles = [];
            translatesX = [];
            translatesY = [];

            for (let k in this.props.attacks) {
                attackAngles[k] = (Math.random() * maxAngle) - (maxAngle / 2);
                translatesX[k] = (Math.random() * maxTranslation) - (maxTranslation / 2);
                translatesY[k] = (Math.random() * maxTranslation) - (maxTranslation / 2);
            }
        }

        if (changed) {
            this.setState({ attackAngles, translatesX, translatesY });
        }
    }

    render() {
        const classes = ["_attacks", this.props.className];

        const attacks = this.props.attacks.map((attack, key) => {
            const attackingPlayer = this.props.player[attack.attackingPlayer];

            return (
                <div
                    className={"_attack"}
                    key={`attack-${key}`}
                    onClick={() => { if (this.props.onAttackClick) { this.props.onAttackClick(key); } }}
                    style={{ transform: `rotate(${this.state.attackAngles[key]}deg)` }}
                >
                    <Card
                        card={attack.attackingCard}
                        className={"attackingCard"}
                        key={attack.attackingCard.id}
                        onClick={() => { if (this.props.onAttackClick) { this.props.onAttackClick(key); } }}
                        style={{ transform: `translateX(${this.state.translatesX[key]}vw) translateY(${this.state.translatesY[key]}vw)` }}
                    />

                    {
                        attack.defendingCard &&
                        <Card
                            card={attack.defendingCard}
                            className={"defendingCard"}
                            key={attack.defendingCard.id}
                        />
                    }

                    <span className={"name"}>{attackingPlayer.name}</span>
                </div>
            );
        });

        return (
            <div className={classes.join(" ")}>
                {attacks}
            </div>
        );
    }
}

Attacks.propTypes = {
    attacks: PropTypes.array,
    className: PropTypes.string,
    onAttackClick: PropTypes.func,
    player: PropTypes.array,
};

export default Attacks;
