import { all, call, put, takeLatest } from 'redux-saga/effects';
import { onCreateSuccess, onCreateError, onJoinSuccess, onJoinError, onStartSuccess, onStartError, onStatusSuccess, onStatusError, onListGamesSuccess, onListGamesError, onKickSuccess, onKickError, onDoneAttackingSuccess, onDoneAttackingError, onDoTakeCardsFromAttacksSuccess, onDoTakeCardsFromAttacksError, onAttackSuccess, onAttackError, onDefendSuccess, onDefendError, onShoveSuccess, onShoveError } from "./actions";
import { create, start, status, join, listGames, kick, attack, defend, doneAttacking, takeCardsFromAttacks, shove } from "./services/MockApi";
import constants from "./constants";

function* errorHandler(error, action, errorFunc) {
    yield put(errorFunc(error));

    if (action.callback) {
        action.callback(null, error);
    }
}

export function* watchDoCreate() {
    yield takeLatest(constants.ACTION_DO_CREATE, performCreate);
}
export function* performCreate(action) {
    try {
        const result = yield call(create, action.playerName, action.gamePassword, action.callback);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onCreateSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onCreateError);
    }
}


export function* watchDoJoin() {
    yield takeLatest(constants.ACTION_DO_JOIN, performJoin);
}
export function* performJoin(action) {
    try {
        const result = yield call(join, action.gameId, action.playerName, action.gamePassword);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onJoinSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onJoinError);
    }
}


export function* watchDoStart() {
    yield takeLatest(constants.ACTION_DO_START, performStart);
}
export function* performStart(action) {
    try {
        const result = yield call(start, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onStartSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onStartError);
    }
}


export function* watchDoStatus() {
    yield takeLatest(constants.ACTION_DO_STATUS, performStatus);
}
export function* performStatus(action) {
    try {
        const result = yield call(status, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onStatusSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onStatusError);
    }
}


export function* watchDoListGames() {
    yield takeLatest(constants.ACTION_DO_LIST_GAMES, performListGames);
}
export function* performListGames(action) {
    try {
        const result = yield call(listGames);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onListGamesSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onListGamesError);
    }
}

export function* watchDoKick() {
    yield takeLatest(constants.ACTION_DO_KICK, performKick);
}
export function* performKick(action) {
    try {
        const result = yield call(kick, action.gameId, action.gamePassword, action.playerId, action.targetPlayerIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onKickSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onKickError);
    }
}


export function* watchDoAttack() {
    yield takeLatest(constants.ACTION_DO_ATTACK, perferomAttack);
}
export function* perferomAttack(action) {
    try {
        const result = yield call(attack, action.gameId, action.gamePassword, action.playerId, action.handIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onAttackSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onAttackError);
    }
}


export function* watchDoShove() {
    yield takeLatest(constants.ACTION_DO_SHOVE, performShove);
}
export function* performShove(action) {
    try {
        const result = yield call(shove, action.gameId, action.gamePassword, action.playerId, action.handIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onShoveSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onShoveError);
    }
}


export function* watchDoDefend() {
    yield takeLatest(constants.ACTION_DO_DEFEND, performDefend);
}
export function* performDefend(action) {
    try {
        const result = yield call(defend, action.gameId, action.gamePassword, action.playerId, action.handIndex, action.attackIndex);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onDefendSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onDefendError);
    }
}


export function* watchDoDoneAttack() {
    yield takeLatest(constants.ACTION_DO_DONE_ATTACKING, performDoneAttacking);
}
export function* performDoneAttacking(action) {
    try {
        const result = yield call(doneAttacking, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onDoneAttackingSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onDoneAttackingError);
    }
}


export function* watchDoTakeCardsFromAttacks() {
    yield takeLatest(constants.ACTION_DO_TAKE_ALL, performDoTakeCardsFromAttacks);
}
export function* performDoTakeCardsFromAttacks(action) {
    try {
        const result = yield call(takeCardsFromAttacks, action.gameId, action.gamePassword, action.playerId);

        if (result.error || result.status === false) {
            throw new Error(result.error);
        }

        yield put(onDoTakeCardsFromAttacksSuccess(result));

        if (action.callback) {
            action.callback(result);
        }
    } catch (error) {
        yield errorHandler(error, action, onDoTakeCardsFromAttacksError);
    }
}



export default function* rootSagas() {
    yield all([
        watchDoCreate(),
        watchDoJoin(),
        watchDoStart(),
        watchDoStatus(),
        watchDoListGames(),
        watchDoKick(),

        watchDoAttack(),
        watchDoShove(),
        watchDoDefend(),
        watchDoTakeCardsFromAttacks(),
        watchDoDoneAttack(),
    ]);
}
