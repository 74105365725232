// initialState
import initialState from "./state";

// redux
import { createStore, applyMiddleware } from 'redux';

// cookie
import Cookies from 'js-cookie';

// reducer
import reducer from "./reducer";

// middleware
import createSagaMiddleware from 'redux-saga';
import { createCookieMiddleware } from 'redux-cookie';

// sagas
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const persistedState = sessionStorage.getItem('reduxState') ? JSON.parse(sessionStorage.getItem('reduxState')) : initialState;

const store = createStore(
    reducer,
    persistedState,
    applyMiddleware(createCookieMiddleware(Cookies), sagaMiddleware)
);


store.subscribe(() => {
    sessionStorage.setItem('reduxState', JSON.stringify(store.getState()));
});


sagaMiddleware.run(sagas);

export default store;
