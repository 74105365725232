
const deckCards = [
    {
        "id": "abf6946f-523e-454c-b91b-91a4b18bae18",
        "color": "CLUBS",
        "value": "QUEEN"
    },
    {
        "id": "51ea7472-8412-4b73-a835-b0a1cc687a83",
        "color": "SPADES",
        "value": "TEN"
    },
    {
        "id": "a2155aaf-df41-4ac8-9374-0ad04291bf40",
        "color": "CLUBS",
        "value": "JACK"
    },
    {
        "id": "fc0e67e7-03a4-465a-87dd-c962cfe965af",
        "color": "CLUBS",
        "value": "NINE"
    },
    {
        "id": "f6587224-85c5-443b-a4a4-06a5dc1765c9",
        "color": "CLUBS",
        "value": "TEN"
    },
    {
        "id": "c208a541-730b-411f-b4a6-5bff24c9bbd9",
        "color": "SPADES",
        "value": "JACK"
    },
    {
        "id": "ff18a40e-70a0-4032-9eaf-e552f42a0748",
        "color": "DIAMONDS",
        "value": "EIGHT"
    },
    {
        "id": "a326cbb9-ee1f-46ae-9e5b-1088139435f2",
        "color": "HEARTS",
        "value": "JACK"
    },
    {
        "id": "27390a59-74bb-4d70-b361-f61009a272bd",
        "color": "HEARTS",
        "value": "EIGHT"
    },
    {
        "id": "af3c4943-3a8c-4e32-8d8d-523259ae196c",
        "color": "DIAMONDS",
        "value": "TEN"
    },
    {
        "id": "b00643ed-afd7-427a-8ef9-3104b9900edc",
        "color": "CLUBS",
        "value": "SIX"
    },
    {
        "id": "f13827f5-40e3-4db5-966f-a1fc5a13c534",
        "color": "SPADES",
        "value": "NINE"
    },
    {
        "id": "c7a78e02-f3e5-4f6a-b02c-3caa3222aa7f",
        "color": "HEARTS",
        "value": "KING"
    },
    {
        "id": "71dc543c-97f4-4e02-8f83-d85ee7850987",
        "color": "SPADES",
        "value": "KING"
    },
    {
        "id": "095349e4-c413-4e99-b3f4-1a5ea767a7b2",
        "color": "DIAMONDS",
        "value": "SIX"
    },
    {
        "id": "e720f091-ebff-4c87-94f4-6818b5787134",
        "color": "HEARTS",
        "value": "ACE"
    },
    {
        "id": "d149bebe-3a5e-46d1-9eb9-d6fdf42563f5",
        "color": "SPADES",
        "value": "ACE"
    },
    {
        "id": "0da97ad4-d3c4-4c60-bf9d-388bb5526b0f",
        "color": "CLUBS",
        "value": "EIGHT"
    },
    {
        "id": "2c0095c1-4d6c-4db9-814b-6485c4fcd68c",
        "color": "SPADES",
        "value": "QUEEN"
    },
    {
        "id": "ba57fb02-87d5-48e3-a8b9-38e4682dc4d8",
        "color": "SPADES",
        "value": "SIX"
    },
    {
        "id": "18b0485b-cf56-4252-b176-aa9d14c15f3d",
        "color": "DIAMONDS",
        "value": "KING"
    },
    {
        "id": "24149099-16a6-44c0-b78e-949b4602c73f",
        "color": "CLUBS",
        "value": "SEVEN"
    },
    {
        "id": "6c00b548-235f-42c0-b0f6-5bb971e7e9d1",
        "color": "CLUBS",
        "value": "ACE"
    },
    {
        "id": "21e4bbed-d145-4ed7-93d1-955cdcab444d",
        "color": "HEARTS",
        "value": "SIX"
    }
];

export default {
    state: {
        gameId: null,

        myKey: null,
        myId: null,

        numberOfCards: deckCards.length,
        lastCard: deckCards[deckCards.length - 1],

        myHand: [
            {
                "id": "089656e2-97b1-4054-9487-41e65d9936b5",
                "color": "DIAMONDS",
                "value": "ACE"
            },
            {
                "id": "1db83cbf-f04b-4da7-bbcf-aa5139f39438",
                "color": "HEARTS",
                "value": "NINE"
            },
            {
                "id": "e720f091-ebff-4c87-94f4-6818b5787134",
                "color": "HEARTS",
                "value": "ACE"
            },
            {
                "id": "ba57fb02-87d5-48e3-a8b9-38e4682dc4d8",
                "color": "SPADES",
                "value": "SIX"
            },
            {
                "id": "ba57fb02-87d5-48e3-a8b9-38e4682dc4d8",
                "color": "SPADES",
                "value": "SIX"
            },
            {
                "id": "1db83cbf-f04b-4da7-bbcf-aa5139f39438",
                "color": "HEARTS",
                "value": "NINE"
            },
        ],

        started: true,

        adminPlayer: null,
        defendingPlayer: null,
        loosingPlayer: null,

        player: [
            {
                name: "Player 1",
                lastCommand: "skip",
                lastInteraction: 0,
                hand: 6,
            },
            {
                name: "Player 2",
                lastCommand: "close",
                lastInteraction: 0,
                hand: 6,
            },
            {
                name: "Player 3",
                lastCommand: "redeal",
                lastInteraction: 0,
                hand: 4,
            },
            {
                name: "Player 4",
                lastCommand: "exchangeOne",
                lastInteraction: 0,
                hand: 8,
            },
        ],

        attacks: [
            {
                attackingPlayer: 0,
                attackingCard: { color: "SPADES", value: "SIX" },
                defendingCard: { color: "SPADES", value: "KING" },
            },
            {
                attackingPlayer: 0,
                attackingCard: { color: "CLUBS", value: "KING" },
                defendingCard: null,
            },
            {
                attackingPlayer: 0,
                attackingCard: { color: "HEARTS", value: "SIX" },
                defendingCard: null,
            },
            {
                attackingPlayer: 0,
                attackingCard: { color: "DIAMONDS", value: "ACE" },
                defendingCard: null,
            },
            {
                attackingPlayer: 0,
                attackingCard: { color: "HEARTS", value: "EIGHT" },
                defendingCard: null,
            },
            {
                attackingPlayer: 0,
                attackingCard: { color: "DIAMONDS", value: "NINE" },
                defendingCard: null,
            }
        ],

        finished: true,

        defendingPlayerWantsToTakeCards: false,
    },

    listOfGames: [],
};
