import initialState from "./state";
import constants from "./constants";

export default (state = initialState, action) => {
    console.log(action);

    switch (action.type) {
        case constants.ACTION_ON_START_SUCCESS:
        case constants.ACTION_ON_CREATE_SUCCESS:
        case constants.ACTION_ON_JOIN_SUCCESS:
        case constants.ACTION_ON_STATUS_SUCCESS:
        case constants.ACTION_ON_ATTACK_SUCCESS:
        case constants.ACTION_ON_DEFEND_SUCCESS:
        case constants.ACTION_ON_DONE_ATTACKING_SUCCESS:
        case constants.ACTION_ON_TAKE_ALL_SUCCESS:
        case constants.ACTION_ON_SHOVE_SUCCESS:
            return {
                ...state,
                state: action.payload
            };

        case constants.ACTION_ON_LIST_GAMES_SUCCESS:
            return {
                ...state,
                listOfGames: action.payload,
            };

        case constants.ACTION_DO_CLEAR:
            return {
                state: initialState.state,
                listOfGames: initialState.listOfGames,
            };

        default:
            return state;
    }
};
