import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { doStart, doKick, doAttack, doDefend, doTakeCardsFromAttacks, doDoneAttacking, doShove } from "../../actions";
import Notifier from "../Notifier";
import Hand from "../Hand";
import Deck from "../Deck";
import Attacks from "../Attacks";
import Opponent from "../Opponent";
import Controls from "../Controls";

// const deepEqual = require("deep-equal");

class PlayScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            handIndex: null,
            attackIndex: null,
        };

        this.handleClickHandCard = this.handleClickHandCard.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleAttackClick = this.handleAttackClick.bind(this);
        this.handleTakeAllClick = this.handleTakeAllClick.bind(this);
        this.handleDoneAttackingClick = this.handleDoneAttackingClick.bind(this);
        this.handleShoveClick = this.handleShoveClick.bind(this);

        this.audioRef = React.createRef();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    _canShove() {
        if (this.props.state.attacks.length === 0) return false;
        if (this.props.state.attacks.filter((attack) => attack.defendingCard === null).length !== this.props.state.attacks.length) return false;

        const firstAttackValue = this.props.state.attacks[0].attackingCard.value;

        if (!this.props.state.attacks.reduce((acc, attack, key) => { return acc && (attack.attackingCard.value === firstAttackValue); }, true)) return false;

        return true;
    }

    handleClickHandCard(index) {
        if (this.props.state.myKey === null) {
            return;
        }

        // attacking
        if (this.props.state.player[this.props.state.myKey].attacking) {
            this.setState({ isLoading: true }, () => {
                this.props.doAttack(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, index, (result, error) => {
                    this.setState({
                        isLoading: false, error, handIndex: null, attackIndex: null,
                    });
                });
            });
        }

        // defending
        if (this.props.state.player[this.props.state.myKey].defending) {
            const attacksWithoutDefendingCard = this.props.state.attacks.filter((attack) => attack.defendingCard === null);
            const onlyOneAttackWithoutDefendingCard = attacksWithoutDefendingCard.length === 1;

            if (onlyOneAttackWithoutDefendingCard || this.state.attackIndex !== null) {
                let defendingCard = this.props.state.myHand[index];
                let firstAttackingCardWithoutDefendingCard = attacksWithoutDefendingCard[0].attackingCard;

                if (firstAttackingCardWithoutDefendingCard.value !== defendingCard.value) {
                    let attackIndex = this.state.attackIndex;

                    if (attackIndex === null) {
                        for (let key in this.props.state.attacks) {
                            let attack = this.props.state.attacks[key];
                            if (attack.defendingCard === null) {
                                attackIndex = key;
                                break;
                            }
                        }
                    }

                    this.setState({ isLoading: true }, () => {
                        this.props.doDefend(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, index, attackIndex, (result, error) => {
                            this.setState({
                                isLoading: false, error, handIndex: null, attackIndex: null,
                            });
                        });
                    });

                    return;

                }
            }

            if (this.state.handIndex === index) {
                index = null;
            }

            this.setState({ handIndex: index });
        }
    }

    handleAttackClick(index) {
        if (this.props.state.myKey === null) {
            return;
        }

        // defending
        if (this.props.state.player[this.props.state.myKey].defending) {
            if (this.state.handIndex !== null) {
                this.setState({ isLoading: true }, () => {
                    this.props.doDefend(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, this.state.handIndex, index, (result, error) => {
                        this.setState({
                            isLoading: false, error, handIndex: null, attackIndex: null,
                        });
                    });
                });
                return;
            }

            this.setState({ attackIndex: index });
        }
    }

    handleTakeAllClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doTakeCardsFromAttacks(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({
                    playFieldIndex: null, handIndex: null, isLoading: false, error
                });
            });
        });
    }

    handleDoneAttackingClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doDoneAttacking(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({
                    playFieldIndex: null, handIndex: null, isLoading: false, error
                });
            });
        });
    }

    handleStartClick() {
        this.setState({ isLoading: true }, () => {
            this.props.doStart(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, (result, error) => {
                this.setState({
                    playFieldIndex: null, handIndex: null, isLoading: false, error
                });
            });
        });
    }

    handleKickClick(playerKey) {
        this.setState({ isLoading: true, playFieldIndex: null, handIndex: null, }, () => {
            this.props.doKick(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, playerKey, (result, error) => {
                this.setState({ playFieldIndex: null, handIndex: null, isLoading: false, error });
            });
        });
    }

    handlePlayFieldSelect(index) {
        if (this.props.state.currentPlayer !== this.props.state.myKey) return;
        if (this.state.playFieldIndex === index) index = null;
        this.setState({ playFieldIndex: index });
    }

    handleShoveClick() {
        if (!this._canShove()) return;

        this.setState({ isLoading: true }, () => {
            this.props.doShove(this.props.state.gameId, this.props.state.gamePassword, this.props.state.myId, this.state.handIndex, (result, error) => {
                this.setState({
                    playFieldIndex: null, handIndex: null, isLoading: false, error
                });
            });
        });
    }

    render() {
        let opponents = JSON.parse(JSON.stringify(this.props.state.player));
        // order the opponents clockwise
        for (let i = 0; i < this.props.state.myKey; i++) {
            opponents.push(opponents.shift());
        }
        // remove us
        opponents.splice(0, 1);

        opponents = opponents.map((player, key) => {
            const rotations = [
                [180],
                [90, -90],
                [90, 180, -90],
            ];

            let rotation = rotations[opponents.length - 1][key];


            const opponentClass = [
                [
                    "top"
                ],
                [
                    "left", "right"
                ],
                [
                    "left", "top", "right"
                ]
            ];
            const opponentStyle = [
                [
                    {
                        transform: `translateY(-50%) translateX(calc(50vw - 50%)) rotate(${rotation}deg)`
                    },
                ],
                [
                    {
                        transform: `translateY(40vh) translateX(-50%) rotate(${rotation}deg)`,
                        left: 0,
                    },
                    {
                        transform: `translateY(40vh) translateX(50%) rotate(${rotation}deg)`,
                        right: 0,
                    }
                ],
                [
                    {
                        transform: `translateY(40vh) translateX(-50%) rotate(${rotation}deg)`,
                        left: 0,
                    },
                    {
                        transform: `translateY(-50%) translateX(calc(50vw - 50%)) rotate(${rotation}deg)`
                    },
                    {
                        transform: `translateY(40vh) translateX(50%) rotate(${rotation}deg)`,
                        right: 0,
                    }
                ],
            ];

            return (
                <Opponent
                    className={opponentClass[opponents.length - 1][key]}
                    key={key}
                    player={player}
                    style={opponentStyle[opponents.length - 1][key]}
                />
            );
        });

        let selectedHandCard = null;
        if (this.state.handIndex !== null) {
            selectedHandCard = this.props.state.myHand[this.state.handIndex];
        }

        return (
            <React.Fragment>
                <Notifier />

                <Controls
                    isLoading={this.state.isLoading}
                    onDoneAttacking={this.handleDoneAttackingClick}
                    onShoveClick={this.handleShoveClick}
                    onStartClick={this.handleStartClick}
                    onTakeAllClick={this.handleTakeAllClick}
                    selectedHandCard={selectedHandCard}
                    state={this.props.state}
                />

                <div className={"_opponents"}>
                    {opponents}
                </div>

                {
                    this.props.state.numberOfCards > 0 &&
                    <Deck
                        lastCard={this.props.state.lastCard}
                        numberOfCards={this.props.state.numberOfCards}
                    />
                }

                {
                    this.props.state.attacks &&
                    <Attacks
                        attacks={this.props.state.attacks}
                        onAttackClick={this.handleAttackClick}
                        player={this.props.state.player}
                    />
                }

                <div className={"_player"}>
                    {
                        this.props.state.myHand &&
                        <Hand
                            hand={this.props.state.myHand}
                            onClickCard={this.handleClickHandCard}
                            selectedCard={this.state.handIndex}
                        />
                    }
                </div>
            </React.Fragment>
        );

    }
}

PlayScreen.propTypes = {
    doAttack: PropTypes.func,
    doDefend: PropTypes.func,
    doDoneAttacking: PropTypes.func,
    doKick: PropTypes.func,
    doStart: PropTypes.func,
    doTakeCardsFromAttacks: PropTypes.func,
    state: PropTypes.object,
};

export default connect(state => {
    return state;
}, {
    doStart, doKick, doAttack, doDefend, doTakeCardsFromAttacks, doDoneAttacking, doShove
})(PlayScreen);

