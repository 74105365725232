import constants from "./constants";

export const doCreate = (playerName, gamePassword, callback) => {
    return { type: constants.ACTION_DO_CREATE, playerName, gamePassword, callback };
};
export const onCreateSuccess = (result) => { return { type: constants.ACTION_ON_CREATE_SUCCESS, ...result }; };
export const onCreateError = (result) => { return { type: constants.ACTION_ON_CREATE_ERROR, ...result }; };


export const doJoin = (gameId, gamePassword, playerName, callback) => {
    return { type: constants.ACTION_DO_JOIN, playerName, gameId, gamePassword, callback };
};
export const onJoinSuccess = (result) => { return { type: constants.ACTION_ON_JOIN_SUCCESS, ...result }; };
export const onJoinError = (result) => { return { type: constants.ACTION_ON_JOIN_ERROR, ...result }; };


export const doStart = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_START, playerId, gameId, gamePassword, callback };
};
export const onStartSuccess = (result) => { return { type: constants.ACTION_ON_START_SUCCESS, ...result }; };
export const onStartError = (result) => { return { type: constants.ACTION_ON_START_ERROR, ...result }; };


export const doStatus = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_STATUS, playerId, gameId, gamePassword, callback };
};
export const onStatusSuccess = (result) => { return { type: constants.ACTION_ON_STATUS_SUCCESS, ...result }; };
export const onStatusError = (result) => { return { type: constants.ACTION_ON_STATUS_ERROR, ...result }; };


export const doListGames = (callback) => {
    return { type: constants.ACTION_DO_LIST_GAMES, callback };
};
export const onListGamesSuccess = (result) => { return { type: constants.ACTION_ON_LIST_GAMES_SUCCESS, ...result }; };
export const onListGamesError = (result) => { return { type: constants.ACTION_ON_LIST_GAMES_ERROR, ...result }; };


export const doKick = (gameId, gamePassword, playerId, targetPlayerIndex, callback) => {
    return { type: constants.ACTION_DO_KICK, gameId, gamePassword, playerId, targetPlayerIndex, callback };
};
export const onKickSuccess = (result) => { return { type: constants.ACTION_ON_KICK_SUCCESS, ...result }; };
export const onKickError = (result) => { return { type: constants.ACTION_ON_KICK_ERROR, ...result }; };


export const doClear = (callback) => {
    return { type: constants.ACTION_DO_CLEAR, callback };
};







export const doAttack = (gameId, gamePassword, playerId, handIndex, callback) => {
    return { type: constants.ACTION_DO_ATTACK, playerId, gameId, gamePassword, handIndex, callback };
};
export const onAttackSuccess = (result) => { return { type: constants.ACTION_ON_ATTACK_SUCCESS, ...result }; };
export const onAttackError = (result) => { return { type: constants.ACTION_ON_ATTACK_ERROR, ...result }; };

export const doShove = (gameId, gamePassword, playerId, handIndex, callback) => {
    return { type: constants.ACTION_DO_SHOVE, playerId, gameId, gamePassword, handIndex, callback };
};
export const onShoveSuccess = (result) => { return { type: constants.ACTION_ON_SHOVE_SUCCESS, ...result }; };
export const onShoveError = (result) => { return { type: constants.ACTION_ON_SHOVE_ERROR, ...result }; };


export const doDefend = (gameId, gamePassword, playerId, handIndex, attackIndex, callback) => {
    return { type: constants.ACTION_DO_DEFEND, playerId, gameId, gamePassword, handIndex, attackIndex, callback };
};
export const onDefendSuccess = (result) => { return { type: constants.ACTION_ON_DEFEND_SUCCESS, ...result }; };
export const onDefendError = (result) => { return { type: constants.ACTION_ON_DEFEND_ERROR, ...result }; };


export const doDoneAttacking = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_DONE_ATTACKING, playerId, gameId, gamePassword, callback };
};
export const onDoneAttackingSuccess = (result) => { return { type: constants.ACTION_ON_DONE_ATTACKING_SUCCESS, ...result }; };
export const onDoneAttackingError = (result) => { return { type: constants.ACTION_ON_DONE_ATTACKING_ERROR, ...result }; };


export const doTakeCardsFromAttacks = (gameId, gamePassword, playerId, callback) => {
    return { type: constants.ACTION_DO_TAKE_ALL, playerId, gameId, gamePassword, callback };
};
export const onDoTakeCardsFromAttacksSuccess = (result) => { return { type: constants.ACTION_ON_TAKE_ALL_SUCCESS, ...result }; };
export const onDoTakeCardsFromAttacksError = (result) => { return { type: constants.ACTION_ON_TAKE_ALL_ERROR, ...result }; };