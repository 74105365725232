import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

class Controls extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    _canShove() {
        if (this.props.state.attacks.length === 0) return false;
        if (this.props.state.attacks.filter((attack) => attack.defendingCard === null).length !== this.props.state.attacks.length) return false;

        const firstAttackValue = this.props.state.attacks[0].attackingCard.value;

        if (!this.props.state.attacks.reduce((acc, attack, key) => { return acc && (attack.attackingCard.value === firstAttackValue); }, true)) return false;
        if (this.props.selectedHandCard === null) return false;
        if (firstAttackValue !== this.props.selectedHandCard.value) return false;

        return true;
    }

    render() {
        const classes = ["_controls", this.props.className];

        const attacking = this.props.state.myKey !== null ? this.props.state.player[this.props.state.myKey].attacking : false;
        const defending = this.props.state.myKey !== null ? this.props.state.player[this.props.state.myKey].defending : false;

        const myName = this.props.state.myKey !== null ? this.props.state.player[this.props.state.myKey].name : null;
        const loosingPlayer = this.props.state.loosingPlayer !== null ? this.props.state.player[this.props.state.loosingPlayer].name : "";
        const defendingPlayer = this.props.state.defendingPlayer !== null ? this.props.state.player[this.props.state.defendingPlayer].name : "";

        return (
            <div className={classes.join(" ")}>

                {
                    /* lets wait for start */
                    this.props.state.finished &&
                    <h2 className="title">{loosingPlayer} hat verloren</h2>
                }

                {
                    /* lets wait for start */
                    !this.props.state.finished &&
                    (!this.props.state.started || this.props.state.finished || this.props.state.done) &&
                    this.props.state.myKey !== this.props.state.adminPlayer &&
                    <h2 className="title">Wir warten auf den Spielstart</h2>
                }

                {
                    /* Attacking? */
                    attacking &&
                    this.props.state.started &&
                    !this.props.state.finished &&
                    <h2 className="title">Du greifst an!</h2>
                }

                {
                    /* Defending? */
                    defending &&
                    this.props.state.started &&
                    !this.props.state.finished &&
                    <h2 className="title">Du verteidigst!</h2>
                }

                {
                    /* defending player wants to take cards */
                    this.props.state.defendingPlayerWantsToTakeCards &&
                    <h2 className="title">{defendingPlayer} möchte aufnehmen</h2>
                }

                {
                    /* start button */
                    (
                        (!this.props.state.started || this.props.state.finished) &&
                        this.props.state.myKey === this.props.state.adminPlayer
                    ) &&
                    <button
                        className={`button start is-large is-primary ${this.props.isLoading ? "is-loading" : ""}`}
                        disabled={
                            (
                                this.props.state.started &&
                                !this.props.state.finished &&
                                !this.props.state.done
                            ) ||
                            this.props.state.myKey !== this.props.state.adminPlayer ||
                            this.props.state.player.length <= 1
                        }
                        onClick={this.props.onStartClick}
                    >Start</button>
                }

                {
                    /* done attacking button */
                    (
                        this.props.state.started &&
                        !this.props.state.finished &&
                        attacking &&
                        this.props.state.attacks &&
                        this.props.state.attacks.length > 0
                    ) &&
                    <button
                        className={`button attackDone is-large is-primary ${this.props.isLoading ? "is-loading" : ""}`}
                        onClick={this.props.onDoneAttacking}
                    >Fertig</button>
                }

                {
                    /* take all button */
                    (
                        this.props.state.started &&
                        !this.props.state.finished &&
                        defending &&
                        this.props.state.attacks &&
                        this.props.state.attacks.length > 0
                    ) &&
                    <button
                        className={`button takeAll is-large is-primary ${this.props.isLoading ? "is-loading" : ""}`}
                        disabled={this.props.state.defendingPlayerWantsToTakeCards}
                        onClick={this.props.onTakeAllClick}
                    >Aufnehmen</button>
                }

                {
                    /* shove button */
                    (
                        this.props.state.started &&
                        !this.props.state.finished &&
                        defending &&
                        this._canShove()
                    ) &&
                    <button
                        className={`button shove is-large is-primary ${this.props.isLoading ? "is-loading" : ""}`}
                        onClick={this.props.onShoveClick}
                    >Schieben</button>
                }

                {
                    /* who are you */
                    false &&
                    <h2 className="title">Du bist {myName}</h2>
                }

                {
                    /* trump color */
                    this.props.state.trumpColor !== null &&
                    <div className={"_trumpColor"}>
                        <Card card={{ color: this.props.state.trumpColor, value: "ACE" }} />
                    </div>
                }
            </div>
        );
    }
}

Controls.propTypes = {
    className: PropTypes.string,

    isLoading: PropTypes.bool,

    onDoneAttacking: PropTypes.func,
    onShoveClick: PropTypes.func,
    onStartClick: PropTypes.func,
    onTakeAllClick: PropTypes.func,

    selectedHandCard: PropTypes.object,
    state: PropTypes.object,
};

export default Controls;
