import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './app/store';

// initialize counterpart
import langEn from "./app/languages/en";
import langDe from "./app/languages/de";

const counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);
counterpart.setLocale(document.getElementsByTagName('html')[0].getAttribute('lang'));

// initialize serviceworker
/*
import * as serviceWorker from "./registerServiceWorker";

const serviceWorkerRegistrationConfig = {
    onUpdate: () => { },
    onSuccess: () => { },
};
serviceWorker.register(process.env.PUBLIC_URL+"/serviceWorker.js", "/", serviceWorkerRegistrationConfig);
serviceWorker.unregister();
*/
ReactDOM.render(
    <Provider store={store}>
        <React.Fragment>
            <div className="vignette" />
            <App />
        </React.Fragment>
    </Provider>,
    document.getElementById('root')
);
