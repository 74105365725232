import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

class Hand extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const cardWidth = 150;
        const pullXPerCardPercent = 33.3;

        const classes = ["_hand", this.props.className];

        const cards = this.props.hand.map((card, k) => {

            let angle = Math.sin(
                (((Math.PI / 2) / this.props.hand.length) * k)
                - .5
            ) * 15;

            let translateY = Math.sin(
                (((Math.PI) / this.props.hand.length) * k)
                + .5
            ) * -4;

            if (this.props.selectedCard === k) {
                translateY -= 20;
            }

            let translateX = pullXPerCardPercent * (k + .5);

            return (
                <Card
                    card={card}
                    key={card ? card.id : Math.random()}
                    onClick={() => {
                        if (this.props.onClickCard) {
                            this.props.onClickCard(k);
                        }
                    }}
                    style={{
                        position: "absolute",
                        transform: `translateY(${translateY}%) rotate(${angle}deg)`,
                        left: `${translateX}%`
                    }}
                />
            );
        });



        return (
            <div
                className={classes.join(" ")}
                style={{
                    width: `${cardWidth + (cardWidth * (this.props.hand.length * (pullXPerCardPercent / 100)))}px`,
                    height: `${cardWidth * 1.55}px`,
                    ...this.props.style,
                }}
            >
                {cards}
            </div>
        );
    }
}

Hand.propTypes = {
    className: PropTypes.string,
    hand: PropTypes.array,
    onClickCard: PropTypes.func,
    selectedCard: PropTypes.number,
    style: PropTypes.object,
};

export default Hand;
