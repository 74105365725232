import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

class Deck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const classes = ["_deck", this.props.className];

        const cards = [];

        for (let i = 0; i < this.props.numberOfCards; i++) {
            cards.push(<Card key={i} />);
        }

        // the deck includes the last card, therefor we need to draw one less
        cards.pop();

        return (
            <div className={classes.join(" ")}>
                {
                    this.props.lastCard &&
                    <Card
                        card={this.props.lastCard}
                        className={"lastCard"}
                    />
                }
                {cards}
                {this.props.numberOfCards} Karten
            </div>
        );
    }
}

Deck.propTypes = {
    className: PropTypes.string,
    lastCard: PropTypes.object,
    numberOfCards: PropTypes.number,
};

export default Deck;
